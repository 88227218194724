AOS.init({
  duration: 800,
  easing: "slide",
  once: true
});

(() => {
  $(".navbar-collapse").on("click", "a:not([data-toggle])", null, () => {
    $(".navbar-collapse").collapse("hide");
  });

  emailjs.init("user_ykq6CFwh6Z3q0t3pSQ8ng");

  let signupForm = $("#signup-form");
  let signupSubmit = $("#signup-form-submit");
  let signupResponse = $("#signup-form-response");

  let contactForm = $("#contact-form");
  let contactSubmit = $("#contact-form-submit");
  let contactResponse = $("#contact-form-response");

  let invalidEmail = "Please enter a valid email address.";

  signupForm.submit(() => {
    hideMessage(signupResponse);
    disableButton(signupSubmit);

    let email = $("#MERGE0").val();
    if (validateEmail(email)) {
      // Email is valid, send data to Mailchimp
      $.ajax({
        type: "GET",
        url:
          "https://epoch-innovations.us20.list-manage.com/subscribe/post-json?c=?",
        data: signupForm.serialize(),
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        error: err => {
          signupForm.trigger("reset");
          enableButton(signupSubmit);
          showMessage(
            signupResponse,
            "failure",
            "Error submitting email address."
          );
        },
        success: data => {
          if (data.result === "success") {
            showMessage(signupResponse, "success", "Thank you!");
          } else {
            showMessage(
              signupResponse,
              "failure",
              "This email cannot be added to this list. Please enter a different email address."
            );
          }
          signupForm.trigger("reset");
          enableButton(signupSubmit);
        }
      });
    } else {
      showMessage(signupResponse, "failure", invalidEmail);
      enableButton(signupSubmit);
    }

    return false;
  });

  contactForm.submit(() => {
    hideMessage(contactResponse);
    disableButton(contactSubmit);

    let name = $("#CF0").val();
    let email = $("#CF1").val();
    let message = $("#CF2").val();

    let params = {
      reply_to: email,
      from_name: name,
      message: message
    };

    if (validateEmail(email)) {
      console.log("Send message with params: " + JSON.stringify(params));

      let serviceId = "default_service";
      let templateId = "epoch_contact_form";

      emailjs.send(serviceId, templateId, params).then(
        () => {
          showMessage(
            contactResponse,
            "success",
            "Thank you for your message."
          );

          contactForm.trigger("reset");
          enableButton(contactSubmit);
        },
        err => {
          console.log("Error sending email: " + JSON.stringify(err));

          showMessage(
            contactResponse,
            "failure",
            "Failed to send message. Please try again."
          );

          contactForm.trigger("reset");
          enableButton(contactSubmit);
        }
      );
    } else {
      showMessage(contactResponse, "failure", invalidEmail);
      enableButton(contactSubmit);
    }

    return false;
  });

  var messageTimeout;

  function showMessage(span, type, text) {
    clearTimeout(messageTimeout);
    span
      .removeClass("success")
      .removeClass("failure")
      .addClass("visible")
      .addClass(type)
      .text(text);

    messageTimeout = window.setTimeout(() => {
      hideMessage(span);
    }, 3500);
  }

  function hideMessage(span) {
    clearTimeout(messageTimeout);
    span.removeClass("visible");
  }

  function disableButton(button) {
    button.attr("disabled", true);
  }

  function enableButton(button) {
    button.attr("disabled", false);
  }

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
})();
